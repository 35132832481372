<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>

          <b-col cols="4">
            <b-form-group
              label="From Any Link of"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="FromLink"
                rules=""
              >
                <b-form-tags
                  v-model="addCourseForm.link"
                  placeholder="Enter Link"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Redirect To"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules=""
              >
                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.to"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="List"
                  :reduce="(val) => val.id"
                  @input="emptyLink"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="addCourseForm.to == 2"
            md="4"
          >
            <b-form-group
              label="Select Course"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules=""
              >
                <v-select
                  v-model="addCourseForm.course"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="coursesList"
                  :reduce="(val) => val"
                  @input="getLink(addCourseForm.course)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Link"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Link"
                target="_blank"
              >
                <b-form-input
                  v-model="addCourseForm.to_link"
                  @input="removeSpace($event)"
                  @change="checkLink(addCourseForm.to_link)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>

          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!--/ form -->
    <link-rediraction-arabic-list ref="table" />
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormTags,

} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { ref, reactive } from '@vue/composition-api'
import Vue from 'vue'
import LinkRediractionArabicList from './LinkRediractionArabicList.vue'

import store from '@/store'

export default {
  setup() {
    const coursesList = ref([])
    const List = ref([
      {
        id: 1,
        name: 'link',
      },
      {
        id: 2,
        name: 'course',
      },
    ])
    const addCourseForm = reactive({
      link: '',
      to: '',
      to_link: '',
    })
    const table = ref()

    store.dispatch('courses/getAllCoursesList').then(response => {
      response.data.forEach(el => {
        if (el.online == 1) {
          coursesList.value.push({
            id: el.id,
            name: `${el.name} Online`,
            link_id: el.link_id,
          })
        } else {
          coursesList.value.push({
            id: el.id,
            name: el.name,
            link_id: el.link_id,
          })
        }
      })
      //   console.log("coursesList", coursesList.value);
    })

    const getLink = item => {
      addCourseForm.to_link = item.link_id
    }

    const addCourseFormvalidate = ref()
    const removeSpace = target => {
      addCourseForm.to_link = target
        .toLowerCase() // LowerCase
        .replace(/\s+/g, '-') // space to -
        .replace(/&/g, '') // & to and
        .replace(/--/g, '-')
        .replace(/[.,]/g, '-')
        .replace(/[_]/g, '')
        .replace(/[!''"":=]/g, '')
        .replace(/[!?%$^~*#()+]/g, '')
    }
    const validLink = ref(true)
    const checkLink = link => {
      const formData = new FormData()

      formData.append('slug', link)
      store.dispatch('courses/checkLink', formData)
        .then(response => {
          validLink.value = response.data.valid
        })
    }
    const emptyLink = () => {
      addCourseForm.to_link = ''
      addCourseForm.course = ''
    }

    const save = () => {
      if (validLink.value == true) {
        const formData = new FormData()

        addCourseFormvalidate.value.validate().then(success => {
          if (success) {
            if (addCourseForm.link.some(el => el === addCourseForm.to_link)) {
              Vue.swal({
                title: '',
                text: 'Those redirections are already there From the source to the destination !!',
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            } else {
              formData.append('from_link', addCourseForm.link)
              formData.append('to_link', addCourseForm.to_link)

              store
                .dispatch('setting/addLinkRedericationArabic', formData)
                .then(response => {
                  addCourseForm.link = ''
                  addCourseForm.to = ''
                  addCourseForm.to_link = ''
                  addCourseForm.course = ''
                  table.value.refetchData()
                  Vue.swal({
                    title: 'Link Arabic Added ',
                    icon: 'success',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                })
                .catch(error => {
                  Vue.swal({
                    title: '',
                    text: `${error.response.data.message}`,
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                })
            }
          }
        })
      } else {
        Vue.swal({
          title: '',
          text: 'Please Enter Valid Link',
          icon: 'error',
          confirmButtonText: 'ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    }

    return {
      removeSpace,
      validLink,
      checkLink,

      addCourseForm,

      addCourseFormvalidate,
      coursesList,

      List,
      getLink,
      save,
      table,
      required,
      email,
      emptyLink,
    }
  },

  components: {
    BCard,

    BFormTags,
    ValidationProvider,
    ValidationObserver,

    BForm,
    LinkRediractionArabicList,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,

    vSelect,
  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style>
  .mt-50.col-12 {
    margin-bottom: 12px !important;
}
</style>
